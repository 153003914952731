@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut.fadexxx {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: revert;
  opacity: 0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn.fadexxx {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: revert;
}
.invisible {
  opacity: 0;
}
.fifty {
  opacity: 0.5;
}

@keyframes slideIn {
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }
}
@keyframes slideOut {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
.fadeIn.slidexxx {
  animation-name: slideIn;
  animation-duration: 1s;
}
.fadeOut.slidexxx {
  animation-name: slideOut;
  animation-duration: 1s;
  right: 100%;
}
